import { setAxiosHeader } from "./auth";
import axios from "axios";

setAxiosHeader();

function Auth(url = "api") {
  return {
    validLogin: async (data) => {
      console.log(data)
      return await axios.post(`${url}/loginJwt`, data);
    },
    login: async (data) => {
      return await axios.post(`${url}/login`, data);
    },
    logout: async () => {
      return await axios.post(`${url}/logout`);
    },
    getCampaign: async () => {
      return await axios.get(`${url}/campaign/all`);
    },
    getCostCenter: async () => {
      return await axios.get(`${url}/costCenter/all`);
    },
    getActions: async () => {
      return await axios.get(`${url}/actions/all`);
    },
    getUser: async () => {
      return await axios.get(`${url}/users/all`);
    },
    getCommission: async () => {
      return await axios.get(`${url}/commissions/all`);
    },
    getCommissionCompleted: async () => {
      return await axios.get(`${url}/commissions/passed`);
    },
    getUserCommissions: async (id) => {
      return await axios.get(`${url}/commission/users/${id}`);
    },
    getHistory: async (id) => {
      return await axios.get(`${url}/commissions/logs/${id}`);
    },
    getHistoryActive: async (id) => {
      return await axios.get(`${url}/commissions/logs/active/${id}`);
    },
    getHistoryAll: async (data) => {
      return await axios.post(`${url}/commissions/logsInRangeDate`, data);
    },
    getDetails: async (id) => {
      return await axios.get(`${url}/commissions/detail/${id}`);
    },
    changePassword: async (data) => {
      return await axios.post(`${url}/changePassword`, data);
    },
    readNotification: async (data) => {
      return await axios.put(`${url}/notifications/updateStatus`, data);
    },
    getNotification: async () => {
      return await axios.get(`${url}/notifications/user`);
    },
    recoverPassword: async (data) => {
      return await axios.post(`${url}/password/create`, data);
    },
    validateToken: async (token) => {
      return await axios.get(`${url}/password/find/${token}`);
    },
    resetPassword: async (data) => {
      return await axios.post(`${url}/password/reset`, data);
    },
    reasonRejected: async () => {
      return await axios.get(`${url}/commissions/reasonRejections`);
    },
    consolidated: async (data) => {
      console.log(data);
      return await axios.post(`${url}/commissions/writeCompleted`, data);
      // return await axios.post(`${url}/commissions/getFileCommissionCompleted`, data);
    },
    getTurn: async (id) => {
      return await axios.get(`${url}/commissions/commissionTurn/${id}`);
    },
    getProgress: async (id) => {
      return await axios.get(`${url}/commissions/progress/${id}`);
    },
  };
}

function Workforce(url = "workforce") {
  return {
    createCommission: async (data) => {
      return await axios.post(`${url}/commission/create`, data);
    },
    addUserCommission: async (data) => {
      return await axios.post(`${url}/commission/assignUserCommission`, data);
    },
    addFileCommission: async (data) => {
      return await axios.post(`${url}/commission/loadCsvCommission`, data);
    },
    getUserMRChispa: async () => {
      return await axios.get(`${url}/users/active/all`);
    },
    inactiveUser: async (commissionId, userId) => {
      return await axios.get(
        `${url}/commission/${commissionId}/user/${userId}`
      );
    },
    getUnloak: async (commissionId, date) => {
      return await axios.get(
        `${url}/commission/unlock/${commissionId}/${date}`
      );
    },
    getClients: async () => {
      return await axios.get(`${url}/getAllClients`);
    },
    getActions: async () => {
      return await axios.get(`${url}/commission/actions`);
    },
    getStatus: async () => {
      return await axios.get(`${url}/getAllStatuses`);
    },
    getClientById: async (id,campaignId) => {
      return await axios.get(`${url}/getConfigCommissionById/${id}/${campaignId}`);
    },
    getStatusUserById: async (id) => {
      return await axios.get(`${url}/getCommissionsStatus/${id}`);
    },
    saveConfigClient: async (data) => {
      return await axios.post(`${url}/createConfigCommissions`, data);
    },
    saveCampaign: async (data) => {
      return await axios.post(`${url}/createCampaigns`, data);
    },
    getUsers: async () => {
      return await axios.get(`${url}/usersAll`);
    },
    getRoles: async () => {
      return await axios.get(`${url}/rolesAll`);
    },
    resetPassword: async (id) => {
      return await axios.get(`${url}/resendPassword/${id}`);
    },
    createUsers: async (data) => {
      return await axios.post(`${url}/createUsers`, data);
    },
    updateUsers: async (data) => {
      return await axios.patch(`${url}/updateUser`, data);
    },
    createCampaign: async (data) => {
      return await axios.post(`${url}/createCampaigns`, data);
    },
    getCampaignClient: async (id) => {
      return await axios.get(`${url}/getCampaignClient/${id}`);
    },
    getCommissionsCancel: async (id) => {
      return await axios.get(`${url}/commission/cancel/${id}`);
    },
    getCommissionsDelete: async (id) => {
      return await axios.delete(`${url}/commission/delete/${id}`);
    },

  };
}

function Directory(url = "directorOperativo") {
  return {
    getDirectory: async () => {
      return await axios.get(`${url}/commission/current`);
    },
    approvedCommission: async (data) => {
      return await axios.post(`${url}/commission/approve`, data);
    },
    getHistoryDirectory: async () => {
      return await axios.get(`${url}/commission/history`);
    },
  };
}

function Manager(url = "gerenteOperativo") {
  return {
    getManager: async () => {
      return await axios.get(`${url}/commission/current`);
    },
    approvedCommission: async (data) => {
      return await axios.post(`${url}/commission/approve`, data);
    },
    getHistoryManager: async () => {
      return await axios.get(`${url}/commission/history`);
    },
  };
}

function ManagerPayRoll(url = "gerenteNomina") {
  return {
    commissionCurrent: async () => {
      return await axios.get(`${url}/commission/current`);
    },
    commissionHistory: async (data) => {
      return await axios.get(`${url}/commission/history`);
    },
    commisionUnlock: async () => {
      return await axios.get(`${url}/commission/unlock`);
    },
    getCommissionsCourse: async () => {
      return await axios.get(`${url}/commission/course`);
    },
    getUnloak: async (commissionId, date) => {
      return await axios.get(
        `${url}/commission/unlock/${commissionId}/${date}`
      );
    },
  };
}

function Coordinator(url = "coordinador") {
  return {
    commissionCurrent: async () => {
      return await axios.get(`${url}/commission/current`);
    },
    approvalCommission: async (data) => {
      return await axios.post(`${url}/commission/approval`, data);
    },
    commissionHistory: async (data) => {
      return await axios.get(`${url}/commission/history`);
    }
  };
}

function accountManager(url = "gerenteCuenta"){
  return {
    commissionCurrent: async () => {
      return await axios.get(`${url}/commission/current`);
    },
    approvalCommission: async (data) => {
      return await axios.post(`${url}/commission/approval`, data);
    },
    commissionHistory: async (data) => {
      return await axios.get(`${url}/commission/history`);
    }
  };
}

export default {
  Auth,
  Workforce,
  Directory,
  Manager,
  ManagerPayRoll,
  Coordinator,
  accountManager
};
